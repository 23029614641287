<script setup lang="ts">
const burgermenuOpen = ref(false)

const closeMenu = () => burgermenuOpen.value = false
</script>

<template>
  <div class="flex flex-col header-shadow">
    <div class="flex flex-row items-center justify-between pt-[1.875rem] pb-[1.125rem] px-9">
      <LocalizedLink v-umami="'bed_exchange_logo_header_mobile'" to="/" @click="closeMenu">
        <BedExchange />
      </LocalizedLink>
      <img v-if="!burgermenuOpen" class="h-8 w-8 cursor-pointer" src="/burger_menu.svg" @click="burgermenuOpen = true">
      <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
      <span v-else class="text-5xl h-8 w-8 cursor-pointer" src="/burger_menu.svg" @click="burgermenuOpen = false">
        &times;
      </span>
    </div>
    <div v-if="burgermenuOpen" class="z-20 w-screen h-screen bg-white flex flex-col gap-y-5 px-9">
      <LocalizedLink v-umami="'profile_button_header_mobile'" class="self-center" to="/profile" @click="closeMenu">
        <p class="text-xl font-bold text-center">
          {{ $t('header.profile') }}
          <Icon class="text-4xl" name="iconamoon:profile-circle" />
        </p>
      </LocalizedLink>
      <LanguageSwitcher @click="closeMenu" />
      <LocalizedLink v-if="useIsConferenceMode()" v-umami="'participate_button_header_mobile'" to="/signUp/" @click="closeMenu">
        <CallToAction>
          {{ $t('index.cta.participate') }}
        </CallToAction>
      </LocalizedLink>
      <div class="h-0 border border-details_light_green" />
      <div class="flex flex-col gap-y-10 text-xl items-center font-bold">
        <LocalizedLink v-umami="'home_header_mobile'" to="/" @click="closeMenu">
          {{ $t('header.home') }}
        </LocalizedLink>
        <LocalizedLink v-umami="'about_us_header_mobile'" to="/AboutUs" @click="closeMenu">
          {{ $t('header.about_us') }}
        </LocalizedLink>
        <LocalizedLink v-umami="'how_it_works_header_mobile'" to="/HowItWorks" @click="closeMenu">
          {{ $t('header.how_it_works') }}
        </LocalizedLink>
        <LocalizedLink v-umami="'faq_header_mobile'" to="/faq" @click="closeMenu">
          {{ $t('header.faq') }}
        </LocalizedLink>
        <LocalizedLink v-umami="'contact_us_header_mobile'" to="/ContactUs" @click="closeMenu">
          {{ $t('header.contact_us') }}
        </LocalizedLink>
      </div>
    </div>
  </div>
</template>
